$control-width: 20rem;
$link-color: #000;
$mydna-blue: #30b5e8;

.toggle-button-goal {
  border: none !important;
  height: 160px !important;
}

.toggle-button {
  border: none !important;
  margin-right: 10px;
  padding: 10px;
}

.subscription-plan {
  margin-left: 5px;
}

.MuiFormControl-root.MuiTextField-root {
  margin: 1rem;
  width: $control-width;
}

.bold {
  font-weight: bold;
}

.TermsAndConditionsLink {
  color: #b0b0b0 !important;
}

$small-device-width: 769px;

@media (min-width: $small-device-width) {
  .mobile {
    display: none;
  }
}

@media (max-width: $small-device-width) {
  .desktop {
    display: none;
  }
}

.inline-buttons {
  div {
    display: inline-block;
    margin: auto;
  }

  button {
    margin-bottom: 2rem;
    margin-top: 2rem;
    min-width: 10rem;
  }
}

.input-control {
  width: $control-width;
}
.input-control-short {
  width: 12rem !important;
  margin-right: -5px;
}

h1 {
  color: $mydna-blue;
  font-family: "Moderat";
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
  line-height: 52px;
  text-transform: uppercase;
}

.title {
  color: #fff;
  font-family: "Moderat";
  font-style: normal;
  line-height: 22px;
  margin: 2rem auto 1rem auto;
  width: $control-width;
  letter-spacing: 1px;
}

.img-container {
  padding-top: 40px;
  text-align: center;

  img {
    width: 130px;
  }
}

.mydna-expansion-panel-item {
  margin-top: -10px;
  margin-left: -15px;
}
.mydna-expansion-panel-item-barcode {
  margin-top: -10px;
}
.mydna-expansion-panel-summary {
  align-self: baseline;
  width: 20rem;
}

.mydna-expansion-panel-summary-title {
  float: left;
  padding-right: 100px;
}

.mydna-expansion-panel-summary-title-icon {
  float: right;
  padding-left: 35px;
}

.MuiExpansionPanelDetails-root {
  display: flex;
  padding: 0 !important;
}

.input-control .phone-number-input {
  font-weight: 900 !important;
}

.mydna-welcome-text {
  color: #fff;
  font-size: 18px;
  line-height: 18px;
}

.MuiLinearProgress-barColorPrimary .MuiMobileStepper-root {
  background-color: red !important;
}

.TotalContainer {
  width: 20rem;
  border-top: 1.5px solid #dedede;
  border-bottom: 1px solid #dedede;
  text-align: left;
}

.TotalContainer-WithoutBottom {
  width: 20.1rem;
  border-top: 1px solid $mydna-blue;
  border-bottom: 1px solid $mydna-blue;
  text-align: left;
  margin: auto;
  padding-top: 10px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.payment-details-label {
  width: 20rem;
  text-align: left;
}

.ssl-icon {
  width: 50px;
  height: 50px;
  padding: 10px;
  margin-top: 5px;
}

.aws-icon {
  width: 57px;
  height: 23px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 14px;
  background-color: white;
}

.payment-container {
  margin: auto;
  width: 20rem;
}

.payment-subcontainer {
  margin: auto;
}

.order-total-container {
  border-top: 1px solid $mydna-blue;
  border-bottom: 1px solid $mydna-blue;
  width: 20rem;
  margin: auto;
  margin-top: 20px;
  padding-top: 10px;
}

.order-total {
  width: 20rem;
  margin: auto;
  height: 1rem;
  padding-right: 10px;
}

.order-total-left {
  float: left;
}

.order-total-right {
  float: right;
  font-size: 17;
  font-weight: bolder;
}

.payment-date {
  font-size: 15px;
  font-weight: 600;
}

.terms-conditions-font {
  font-weight: 600;
  color: #b0b0b0;
  font-size: 14px;
}

.payment-expansion-panel {
  margin-left: 5px;
  width: 20rem;
  margin-bottom: 10px;
}

.payment-expansion-panel-paper {
  text-align: left;
  height: 2rem;
  padding: 10px;
}

.greyed {
  background: #dedede;
}

.payment-expansion-panel-content-left {
  height: 1.5rem;
  float: left;
}

.payment-expansion-panel-content-right {
  height: 1.5rem;
  float: right;
  text-align: right;
}

.appbar {
  background-color: #081f2d;
}

.TextBox-Title {
  padding: 10px;
  text-align: left;
  margin-left: -10px;
}

.goal-section {
  padding-bottom: 30px;
}

.SubmitButton {
  font-size: 18px !important;
  border-radius: 6px !important;
  width: 19rem;
  background-color: $mydna-blue;
}

.BarcodeButton {
  color: $mydna-blue !important;
  margin: 10px;
  font-size: 25px !important;
  margin: 20px !important;
  width: 90px;
  height: 60px;
}
.selected {
  border: 1.5px solid $mydna-blue !important;
}

.VerifyButton {
  margin: 10px;
  margin-top: 18px !important;
  width: 5rem;
  height: 3rem;
  background-color: #fff;
  color: $mydna-blue;
}
.BackButton {
  background-color: #fff !important;
  font-size: 16px !important;
  text-transform: none !important;
  text-align: center !important;
}

.ReturningUserText {
  font-size: 18px;
  letter-spacing: 1.8px;
}

.SignupReturnLink {
  padding-left: 10px;
}

.MuiLinearProgress-colorPrimary {
  background-color: #e9e9e9 !important;
}

.error {
  color: red;
  width: 21rem;
  padding-top: 10px;
  margin: auto;
}
.small {
  width: 12rem;
}

.confirmation-store-icons {
  display: flex;
}

.confirmation-text,
.no-decor {
  font: Black;
  font-size: 24px;
  color: $mydna-blue;
}

.no-decor {
  text-decoration: none;
}
.store-badges {
  margin: 15px;
  width: 8rem;
  height: 2.5rem;
}

.bottom-margin-div {
  margin-bottom: 200px;
}

.dropdownContent {
  text-align: left;
  padding-left: 5px;
  color: white;
}

.password-help-text {
  font-size: 13px;
  font-style: italic;
  margin: auto;
  width: 25em;
  color: grey;
}

.bottom-margin {
  margin-bottom: 250px;
}

.subscription-button {
  margin: 0px 10px;
  transition: 0.2s;
}

.MuiLinearProgress-barColorPrimary {
  background-color: green !important;
}

.content {
  margin: auto;
  padding: 20px;
}

.content-width {
  width: fit-content;
}

.spaced-container {
  display: flex;
  flex-direction: row;
  width: 21rem;
  margin: auto;
}

.spaced-container > div:first-of-type {
  margin-right: 10px;
}

.confirmation-store-icons {
  display: flex;
}

.store-badges {
  margin: 15px;
  width: 8rem;
  height: 2.5rem;
}

p {
  color: #fff;
}

.MuiToggleButton-root {
  text-transform: none !important;
}

.order-total-container {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 21rem;
  margin-top: 20px;
  padding-top: 10px;
  color: white;
}

.order-total {
  width: 20rem;
  margin: auto;
  height: 3rem;
  flex: 1;
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.value-container {
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.value-indicator {
  background-color: #a3f89b;
  width: 7rem;
  height: 1rem;
  text-transform: none;
  margin-left: -200px;
  border-radius: 3px;
  padding: 5px;
}

.value-indicator-empty {
  width: 7rem;
  height: 1rem;
  padding: 5px;
}

.titleLevel1 {
  font-weight: 400;
  font-size: 15px;
  text-transform: none;
  margin-left: 7px;
}

.darkColoredText {
  color: #b0b0b0;
  font-size: 11px;
  line-height: 1.1;
  padding: 0px 10px 0px 10px;
}
.chargeAmountFont {
  font-size: 22px;
  color: #b0b0b0;
  margin: 35px 0px 35px 0px;
}

.chargeAmountSubtleFont {
  font-size: 12px;
  color: #b0b0b0;
}

@media only screen and (max-device-width: 480px) {
  .subscription-options-container {
    overflow-y: scroll;
  }
}

.coupon-input {
  flex-grow: 1;
}

.couponSubmission {
  margin-top: 35px !important;
}

.input-label {
  color: white !important;
  text-align: left !important;
  margin-bottom: 3px !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
}

.background {
  border: 1px solid white;
  height: 40px;
  width: "100%";
  border-radius: 5px;
  padding-top: 20px;
}

.card-textfield {
  padding-left: 10px;
  padding-right: 10px;
}

.small {
  width: 10rem;
}

.success{
  color: green;
}

.mydna-signup-form {
  .terms-conditions-container {
    margin-top: 10px;
  }

} 
@font-face {
  font-family: "Moderat";
  src: url(./Assets/fonts/Moderat-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Moderat";
  src: url(./Assets/fonts/Moderat-Regular.otf) format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "Moderat";
  src: url(./Assets/fonts/Moderat-Thin.otf) format("opentype");
  font-weight: lighter;
}

body {
  margin: 0;
  font-family: 'Moderat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
